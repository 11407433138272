<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='emailListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>
              <!-- Cliente-->
              <div>
                <div>
                  <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                </div>
              </div>
              <!-- Solicitante -->
              <div >
                <div>
                  <multiple-select labelField="nome" :options="employeeList" :id-field="'id_employee'"
                                   :placeholder="this.$i18n.t('form.oportunidade.solicitante')" :fetchOptions="employeeFetchOptions"
                                   :value="filters.filEmployees" />
                </div>
              </div>

              <!-- Criado por -->
              <div>
                <div>
                  <multiple-select labelField="nome" :options="userList" :id-field="'id'" :placeholder="this.$i18n.t('form.oportunidade.responsavel')" :fetchOptions="userFetchOptions" :value="filters.filUsers" />
                </div>
              </div>

              <!-- Origem e Destino-->
              <h6 class="mb-3 mt-5">{{ $t('origin_and_destination') }}</h6>
              <div class="row">
                <div class="col-md-6">
                  <div class="py-1">
                    <div class="md-form mb-0">
                      <input  ref="origin"  id="origin" :value="filters.filOrigem" class="md-form-control" type="text">
                      <label :class="{'active': filters.filOrigem}">{{ $t('kpi.value_freight.Origin') }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="py-1">
                    <div class="md-form mb-0">
                      <input  ref="destination"  id="destination" :value="filters.filDestino" class="md-form-control" type="text">
                      <label :class="{'active': filters.filDestino}">{{ $t('kpi.value_freight.Destiny') }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 0 }"
                     v-on:click="applyStatusFilter(0)" data-toggle="tab">{{ $t('form.mail.statusList.pending') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.mail.statusList.processed')}}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.mail.statusList.dropped') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 3 }"
                     v-on:click="applyStatusFilter(3)" data-toggle="tab">{{ $t('form.mail.statusList.delivered') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 4 }"
                     v-on:click="applyStatusFilter(4)" data-toggle="tab">{{ $t('form.mail.statusList.error') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 5 }"
                     v-on:click="applyStatusFilter(5)" data-toggle="tab">{{ $t('form.mail.statusList.bounce') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 6 }"
                     v-on:click="applyStatusFilter(6)" data-toggle="tab">{{ $t('form.mail.statusList.blocked') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 7 }"
                     v-on:click="applyStatusFilter(7)" data-toggle="tab">{{ $t('form.mail.statusList.open') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 8 }"
                     v-on:click="applyStatusFilter(8)" data-toggle="tab">{{ $t('form.mail.statusList.click') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 9 }"
                     v-on:click="applyStatusFilter(9)" data-toggle="tab">{{ $t('form.mail.statusList.spamreport') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 10 }"
                     v-on:click="applyStatusFilter(10)" data-toggle="tab">{{ $t('form.mail.statusList.unsubscribe') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 11 }"
                     v-on:click="applyStatusFilter(11)" data-toggle="tab">{{ $t('form.mail.statusList.group_unsubscribe') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 12 }"
                     v-on:click="applyStatusFilter(12)" data-toggle="tab">{{ $t('form.mail.statusList.group_resubscribe') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 13 }"
                     v-on:click="applyStatusFilter(13)" data-toggle="tab">{{ $t('form.mail.statusList.deferred') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <custom-vue-table
                  ref="EmailListTable"
                  http-method="get"
                  :api-url="listEndpoint"
                  :fields="fieldsTable"
                  :sort-order="sortOrder"
                  :append-params="activeFilters"
                  track-by="id"
                >
                  <template slot="actions" slot-scope="props">
                    <div class="custom-actions">
                      <a class="btn btn-link"
                         v-bind:href="$util.mountLink('MailV2Detail', props.rowData)">
                        <i class="text-primary  rc-Ativo-210 font-20"></i>
                      </a>
                    </div>
                  </template>
                </custom-vue-table>
                <!-- END Table -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import TaskLog from '@/views/Schedules/TaskLog.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CustomVueTable from '@/components/Table/CustomVueTable'

// Components
import AddInfo from '@/views/Tools/Oportunidade/AddInfo'

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import TaskService from '@/services/TaskService'
import CustomerService from '@/services/CustomerService'
import EmployeeService from '@/services/EmployeeService'

export default {
  name: 'OportunidadeList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('oportunidades.title') + ' - %s'
    }
  },
  data () {
    return {
      userList: [],
      employeeList: [],
      customerList: [],
      isLoading: false,
      fullPage: true,
      listEndpoint: process.env.VUE_APP_APIENDPOINT + 'v3/mail',
      activeFilterList: {
        users: null,
        customer: null,
        id_status: null,
        origin: null,
        destination: null
      },
      filter: {},
      sortOrder: [
        { field: 'created_at', direction: 'desc' },
        { field: 'type', direction: 'asc' }
      ]
    }
  },
  components: {
    CustomVueTable,
    FilterSideBar,
    Loading,
    FilterContainer,
    MultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {
    this.filter.internal_code = this.$route.params.process
    this.filters.filProcesso = this.$route.params.process
    this.filter.id_status = null
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    userFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getUsuarios(filters).then(res => {
          _this.userList = res.data.data
          resolve(_this.userList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getFuncionarios(filters).then(res => {
          _this.employeeList = res.data.data
          resolve(_this.employeeList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    showModal () {
      this.$modal.show(TaskLog, {}, {
        draggable: false,
        adaptive: true,
        scrollable: true,
        clickToClose: false,
        width: '80%',
        height: 'auto'
      })
    },
    // Delete
    onDelete (task) {
      this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja excluir esta tarefa.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.delete(task)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Tarefa excluída com sucesso!')
        }
      })
    },
    async delete (task) {
      let _this = this
      _this.isLoading = true

      await TaskService.deleteTask(task.id).then(task => {
        _this.isLoading = false
        this.$refs.EmailListTable.refresh()
      })
    },
    // Force Send
    onSendNow (task) {
      var _this = this
      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja enviar esse tarifário agora.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.sendNow(task)).then((result) => {
        if (result.value) {
          _this.$alertSwal.toast('Tarifário adicionado na fila de envio!')
        }
      })
    },
    async sendNow (task) {
      let _this = this
      _this.isLoading = true
      await TaskService.forceSendTask(task.id).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    // Send Test
    onSendTestNow (task) {
      let _this = this
      _this.sendTestNow(task).then((result) => {
        _this.$alertSwal.toast('Tarifário adicionado na fila de envio!')
      })
    },
    async sendTestNow (task) {
      let _this = this
      _this.isLoading = true
      await TaskService.testSendTask(task.id).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id: object.id } }).href
    },
    mountLinkCopy (itemName, object) {
      return this.$router.resolve({ name: itemName, query: { id: object.id } }).href
    },
    buildFilters () {
      this.filter.users = (this.filters.filUsers) ? this.filters.filUsers.map(x => (x.id)) : []
      this.filter.employees = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
      // this.filter.id_status = null

      // Origins and destination
      this.filter.origin = this.filters.filOrigem
      this.filter.destination = this.filters.filDestino
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.EmailListTable.refresh()
    },
    // Loader
    applyFilter () {
      this.customerList = []
      this.userList = []

      // Origin and Destination
      this.filters.filOrigem = this.$refs.origin.value ? this.$refs.origin.value : null
      this.filters.filDestino = this.$refs.destination.value ? this.$refs.destination.value : null
      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.EmailListTable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.filters.filCliente = []
      this.filters.filEmployees = []
      this.filters.filUsers = []
      this.filters.filOrigem = null
      this.filters.filDestino = null

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.EmailListTable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      let statusList = [
        {
          'id': 0,
          'label': this.$i18n.t('form.mail.statusList.pending'),
          'color': '#ababab'
        },
        {
          'id': 1,
          'label': this.$i18n.t('form.mail.statusList.processed'),
          'color': '#5ec65f'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.mail.statusList.dropped'),
          'color': '#F3614E'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.mail.statusList.delivered'),
          'color': '#5ec65f'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.mail.statusList.error'),
          'color': '#F3614E'
        },
        {
          'id': 5,
          'label': this.$i18n.t('form.mail.statusList.bounce'),
          'color': '#F3614E'
        },
        {
          'id': 6,
          'label': this.$i18n.t('form.mail.statusList.blocked'),
          'color': '#F3614E'
        },
        {
          'id': 7,
          'label': this.$i18n.t('form.mail.statusList.open'),
          'color': '#5ec65f'
        },
        {
          'id': 8,
          'label': this.$i18n.t('form.mail.statusList.click'),
          'color': '#5ec65f'
        },
        {
          'id': 9,
          'label': this.$i18n.t('form.mail.statusList.spamreport'),
          'color': '#F3614E'
        },
        {
          'id': 10,
          'label': this.$i18n.t('form.mail.statusList.unsubscribe'),
          'color': '#F3614E'
        },
        {
          'id': 11,
          'label': this.$i18n.t('form.mail.statusList.group_unsubscribe'),
          'color': '#F3614E'
        },
        {
          'id': 12,
          'label': this.$i18n.t('form.mail.statusList.group_resubscribe'),
          'color': '#5ec65f'
        },
        {
          'id': 13,
          'label': this.$i18n.t('form.mail.statusList.deferred'),
          'color': '#F3614E'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    formatType (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.mail.typeList.sender'),
          'color': '#ababab'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.mail.typeList.recipient'),
          'color': '#ababab'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.mail.typeList.cc'),
          'color': '#5ec65f'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.mail.typeList.cco'),
          'color': '#F3614E'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return status.label
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      this.$router.push({ name: 'ScheduleEdit', params: { id_schedule: row.data.id } })
    },
    changeStatus (rowData) {
      let _this = this
      this.$modal.show(AddInfo, { modalData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': _this.onCloseModal
        }
      )
    },
    onCloseModal () {
      this.$refs.EmailListTable.refresh()
    }
  },
  computed: {
    activeFilters () {
      return this.filter
    },
    fieldsTable () {
      return [
        {
          name: 'subject',
          title: this.$i18n.t('form.mail.subject'),
          sortField: 'subject',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'email',
          title: this.$i18n.t('form.mail.email'),
          sortField: 'email',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'type',
          title: this.$i18n.t('form.mail.type'),
          sortField: 'type',
          formatter: (value) => this.formatType(value)
        },

        {
          name: 'created_at',
          title: this.$i18n.t('form.mail.created_at'),
          sortField: 'created_at',
          formatter: (value) => this.$util.formatDateTime(value)
        },
        {
          name: 'id_status',
          title: this.$i18n.t('form.mail.status.title'),
          sortField: 'id_status',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isPricing () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_PRICING') !== -1) {
        return true
      }
      return false
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .btn-padding {
    padding: 0.55rem 0.30rem
  }
</style>
